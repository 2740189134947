import PropTypes from "prop-types";
import React from "react";
import { defineMessages, intlShape, injectIntl } from "react-intl";
import VM from "scratch-vm";

import AssetPanel from "../components/asset-panel/asset-panel.jsx";
import playIconSvg from "../components/asset-panel/play.svg";
import stopIconSvg from "../components/asset-panel/stop.svg";

import errorBoundaryHOC from "../lib/error-boundary-hoc.jsx";
import DragConstants from "../lib/drag-constants";

import CodeMirror from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";

import { connect } from "react-redux";

import {
    closeSoundLibrary,
    openSoundLibrary,
    openSoundRecorder,
} from "../reducers/modals";

import { activateTab, COSTUMES_TAB_INDEX } from "../reducers/editor-tab";

import { setRestore } from "../reducers/restore-deletion";
import { showStandardAlert, closeAlertWithId } from "../reducers/alerts";

const users = [
    { label: "@Walter White" },
    { label: "@皮皮鲁" },
    { label: "@鲁西西" },
    { label: "@中本聪" },
    { label: "@サトシ・ナカモト" },
    { label: "@野比のび太" },
    { label: "@성덕선" },
];

const PythonTab = (props) => {
    const messages = defineMessages({
        runPython: {
            defaultMessage: "Run Python Script",
            description: "Button To Run Python Script",
            id: "gui.pythonTab.runPython",
        },
        stopPython: {
            defaultMessage: "Stop Python Script",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.stopPython",
        },
        motorALeft: {
            defaultMessage: "#Turn Motor A Left",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.motorALeft",
        },
        motorBLeft: {
            defaultMessage: "#Turn Motor B Left",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.motorBLeft",
        },
        motorARight: {
            defaultMessage: "#Turn Motor A Right",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.motorARight",
        },
        motorBRight: {
            defaultMessage: "#Turn Motor B Right",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.motorBRight",
        },
        speedRange: {
            defaultMessage: "#Set Speed Range (0-100)",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.speedRange",
        },
        moveInDirection: {
            defaultMessage: "#Move In Direction FORWARD/BACKWARD/LEFT/RIGHT",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.moveInDirection",
        },
        moveDistance: {
            defaultMessage: "#Move Distance CM",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.moveDistance",
        },
        turnInDirection: {
            defaultMessage: "#Turn in Direction LEFT/RIGHT",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.turnInDirection",
        },
        setEyesColor: {
            defaultMessage: "#Set Eyes Color",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.setEyesColor",
        },
        delay: {
            defaultMessage: "#Delay For Seconds",
            description: "Button To Stop Python Script",
            id: "gui.pythonTab.delay",
        },
    });
    const monacoRef = React.useRef(null);
    const {
        dispatchUpdateRestore, // eslint-disable-line no-unused-vars
        intl,
        isRtl,
        vm,
        onNewSoundFromLibraryClick,
        onNewSoundFromRecordingClick,
        playPython,
        stopPython,
    } = props;
    const [code, setCode] = React.useState(`"""
LogoBot Api
 _ TurnMotor_A_Left() ${intl.formatMessage(messages.motorALeft)}
 _ TurnMotor_B_Left() ${intl.formatMessage(messages.motorBLeft)}
 _ TurnMotor_A_Right() ${intl.formatMessage(messages.motorARight)}
 _ TurnMotor_B_Right() ${intl.formatMessage(messages.motorBRight)}
 _ SetMotorSpeed(speed = 100) ${intl.formatMessage(messages.speedRange)}
 _ MoveInDirection(direction = Direction.FORWARD) ${intl.formatMessage(
     messages.moveInDirection
 )}
 _ MoveDistance(direction = Direction.FORWARD,distance = 15) ${intl.formatMessage(
     messages.moveDistance
 )}
 _ TurnInDirection(direction = Direction.LEFT, angle=90) ${intl.formatMessage(
     messages.turnInDirection
 )}
 _ SetEyesColor(color = 'FFFFFF') ${intl.formatMessage(messages.setEyesColor)}
 _ Delay(seconds = 2) ${intl.formatMessage(messages.delay)}
"""
    `);

    function handleEditorDidMount(editor, monaco) {
        monaco.config({
            urls: {
                monacoLoader: uriFromPath(
                    path.join(
                        __dirname,
                        "../../../scratch-desktop/node_modules/monaco-editor/min/vs/loader.js"
                    )
                ),
                monacoBase: uriFromPath(
                    path.join(
                        __dirname,
                        "../../../scratch-desktop/node_modules/monaco-editor/min/vs"
                    )
                ),
            },
        });
        monacoRef.current = editor;
    }

    return (
        <AssetPanel
            widthPannel={70}
            buttons={[
                {
                    title: intl.formatMessage(messages.runPython),
                    img: playIconSvg,
                    onClick: () => {
                        playPython({ code, vm });
                        // vm.runtime?.char.writeValue(
                        //             new TextEncoder().encode("f")
                        //         );
                    },
                },
                {
                    title: intl.formatMessage(messages.stopPython),
                    img: stopIconSvg,
                    onClick: () => {
                        stopPython();
                    },
                    // fileAccept: '.wav, .mp3',
                    // fileChange: ()=>{},
                    // fileInput: ()=>{},
                    // fileMultiple: true
                },
                //  {
                //     // title: intl.formatMessage(messages.surpriseSound),
                //     title : "afaq",
                //     img: surpriseIcon,
                //     onClick: ()=>{}
                // }, {
                //     // title: intl.formatMessage(messages.recordSound),
                //     title : "afaq",
                //     img: addSoundFromRecordingIcon,
                //     onClick: ()=>{}
                // }, {
                //     // title: intl.formatMessage(messages.addSound),
                //     title : "afaq",
                //     img: searchIcon,
                //     onClick: ()=>{}
                // }
            ]}
            dragType={DragConstants.SOUND}
            isRtl={true}
            items={[]}
            selectedItemIndex={null}
            onDeleteClick={() => {}}
            onDrop={() => {}}
            onDuplicateClick={() => {}}
            onExportClick={() => {}}
            onItemClick={() => {}}
        >
            <CodeMirror
                width="60vw"
                value={code}
                height="80vh"
                extensions={[python()]}
                onChange={(val) => {
                    setCode(val);
                }}
            />
        </AssetPanel>
    );
};

// class PythonTab extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             code: "// type your code...",
//         };
//     }

//     componentWillReceiveProps(nextProps) {}

//     handleEditorDidMount (_,editor) {

//     }

//     render() {
//         const {
//             dispatchUpdateRestore, // eslint-disable-line no-unused-vars
//             intl,
//             isRtl,
//             vm,
//             onNewSoundFromLibraryClick,
//             onNewSoundFromRecordingClick,
//         } = this.props;

//         const messages = defineMessages({
//             runPython: {
//                 defaultMessage: "Run Python Script",
//                 description: "Button To Run Python Script",
//                 id: "gui.pythonTab.runPython",
//             },
//             stopPython: {
//                 defaultMessage: "Stop Python Script",
//                 description: "Button To Stop Python Script",
//                 id: "gui.pythonTab.stopPython",
//             },
//         });

//         return (
//             <AssetPanel
//                 widthPannel={70}
//                 buttons={[
//                     {
//                         title: intl.formatMessage(messages.runPython),
//                         img: playIconSvg,
//                         onClick: () => {
//                             this.props.playPython("print(afq)");
//                         },
//                     },
//                     {
//                         title: intl.formatMessage(messages.stopPython),
//                         img: stopIconSvg,
//                         onClick: () => {},
//                         // fileAccept: '.wav, .mp3',
//                         // fileChange: ()=>{},
//                         // fileInput: ()=>{},
//                         // fileMultiple: true
//                     },
//                     //  {
//                     //     // title: intl.formatMessage(messages.surpriseSound),
//                     //     title : "afaq",
//                     //     img: surpriseIcon,
//                     //     onClick: ()=>{}
//                     // }, {
//                     //     // title: intl.formatMessage(messages.recordSound),
//                     //     title : "afaq",
//                     //     img: addSoundFromRecordingIcon,
//                     //     onClick: ()=>{}
//                     // }, {
//                     //     // title: intl.formatMessage(messages.addSound),
//                     //     title : "afaq",
//                     //     img: searchIcon,
//                     //     onClick: ()=>{}
//                     // }
//                 ]}
//                 dragType={DragConstants.SOUND}
//                 isRtl={true}
//                 items={[]}
//                 selectedItemIndex={null}
//                 onDeleteClick={() => {}}
//                 onDrop={() => {}}
//                 onDuplicateClick={() => {}}
//                 onExportClick={() => {}}
//                 onItemClick={() => {}}
//             >
//                 <CodeMirror width="60vw" value={`console.log('afaq');`} height="80vh" extensions={[python()]} onChange={(val)=>{
//                     console.log(val);
//                 }} />;
//                 {/* <Editor
//                     height="80vh"
//                     defaultLanguage="python"
//                     editorDidMount={this.handleEditorDidMount}
//                     defaultValue="// some comment"
//                     beforeMount={()=>{}}
//                     onMount={()=>{}}
//                     onChange={()=>{}}
//                     onValidate={()=>{}}

//                 /> */}
//                 {/* <CodeEditor></CodeEditor> */}
//             </AssetPanel>
//         );
//     }
// }

PythonTab.propTypes = {
    dispatchUpdateRestore: PropTypes.func,
    editingTarget: PropTypes.string,
    intl: intlShape,
    isRtl: PropTypes.bool,
    onActivateCostumesTab: PropTypes.func.isRequired,
    onCloseImporting: PropTypes.func.isRequired,
    onNewSoundFromLibraryClick: PropTypes.func.isRequired,
    onNewSoundFromRecordingClick: PropTypes.func.isRequired,
    onRequestCloseSoundLibrary: PropTypes.func.isRequired,
    onShowImporting: PropTypes.func.isRequired,
    soundLibraryVisible: PropTypes.bool,
    soundRecorderVisible: PropTypes.bool,
    sprites: PropTypes.shape({
        id: PropTypes.shape({
            sounds: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                })
            ),
        }),
    }),
    stage: PropTypes.shape({
        sounds: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
            })
        ),
    }),
    vm: PropTypes.instanceOf(VM).isRequired,
    playPython: PropTypes.func,
    stopPython: PropTypes.func,
};

const mapStateToProps = (state) => ({
    editingTarget: state.scratchGui.targets.editingTarget,
    isRtl: state.locales.isRtl,
    sprites: state.scratchGui.targets.sprites,
    stage: state.scratchGui.targets.stage,
    soundLibraryVisible: state.scratchGui.modals.soundLibrary,
    soundRecorderVisible: state.scratchGui.modals.soundRecorder,
});

const mapDispatchToProps = (dispatch) => ({
    onActivateCostumesTab: () => dispatch(activateTab(COSTUMES_TAB_INDEX)),
    onNewSoundFromLibraryClick: (e) => {
        e.preventDefault();
        dispatch(openSoundLibrary());
    },
    onNewSoundFromRecordingClick: () => {
        dispatch(openSoundRecorder());
    },
    onRequestCloseSoundLibrary: () => {
        dispatch(closeSoundLibrary());
    },
    dispatchUpdateRestore: (restoreState) => {
        dispatch(setRestore(restoreState));
    },
    onCloseImporting: () => dispatch(closeAlertWithId("importingAsset")),
    onShowImporting: () => dispatch(showStandardAlert("importingAsset")),
});

export default errorBoundaryHOC("Python Tab")(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(PythonTab))
);

// export default () => {
//     const classes = useStyles();
//     return (
//         <AllowanceClass classes={classes} />
//     )
// }
